import React, {useEffect} from 'react';
import {Grid} from 'semantic-ui-react';
import LogTable from './LogTable/LogTable';
import XmlHrefBlock from './XmlHrefBlock';
import {InformationPanelItem} from './InformationPanelItem';
import {DashboardItem} from './DashboardItem';
import {observer} from 'mobx-react-lite';
import './Style.css';
import store from "../../Stores/ProfileInfoStore";
import templateListStore from "../../Stores/TemplateListStore";
import {WelcomeScreen} from "../../Components/WelcomeScreen/WelcomeScreen";

function InformationPanel() {
    useEffect(() => {
        void store.load();
        void templateListStore.load();
    }, [])
    if (store.isLoaded && !store.integrations.length) {
        return (
            <InformationPanelItem header={"Знакомство"}>
                <WelcomeScreen />
            </InformationPanelItem>
        )
    }
    return (
        <>
            <InformationPanelItem header="Обновление" key="Обновление">
                <Grid>
                    <DashboardItem caption="Последнее обновление XML" value={store.lastExecution}/>
                    <DashboardItem caption="Следующее обновление XML" value={store.nextExecution} wide/>
                    <DashboardItem caption="Статус последнего обновления" value={store.lastJobState}/>
                </Grid>
            </InformationPanelItem>
            <InformationPanelItem header="Выгрузка" key="Выгрузка">
                <Grid>
                    <DashboardItem caption="Количество товаров" value={store.productCount.toString()}/>
                    <DashboardItem caption="Количество категорий" value={store.activeFoldersCount.toString()} wide/>
                    <DashboardItem caption="Количество складов" value={store.activeStoresCount.toString()}/>
                </Grid>
            </InformationPanelItem>
            <InformationPanelItem header="Ссылки на файлы XML" key="Ссылки на файлы XML" smallTopPadding
                                  loading={store.isLoading}
                                  visible={(store.files && store.files.length > 0) || store.logUrl !== undefined}>
                {store.files && store.files.length > 0 &&
                    store.files.map((file) =>
                        <XmlHrefBlock
                            file={file}
                            downloadUrl={store.downloadUrl}
                            key={file.xmlUrl}
                        />)
                }
                {store.logUrl &&
                    <div className={"logHref"}>
                        <a href={store.logUrl}>Журнал выгрузки</a>
                    </div>
                }
            </InformationPanelItem>
            {store.integrations && store.integrations.length > 0 &&
                <Grid.Row>
                    <LogTable/>
                </Grid.Row>
            }
        </>
    );
}

export default observer(InformationPanel);