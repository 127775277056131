import { observer } from 'mobx-react-lite';
import React from 'react'
import { Checkbox, List } from 'semantic-ui-react'
import skladStore from "../../../../Stores/SkladStore";
import Store from "../../../../Entities/Store";

interface IStoreProps {
    store: Store
}

function StoreItem(props: IStoreProps) {
    const { store } = props;
    return (
        <List.Item style={{ overflow: 'hidden' }}>
            <Checkbox label={store.name}
                      checked={store.isActive}
                      onChange={(_, data) => { store.changeActive(data.checked!); skladStore.hasChanges = true; }} />
        </List.Item>
    )
}

export default observer(StoreItem);
