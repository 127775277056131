import {computed, observable, runInAction} from 'mobx'
import {Category, TemplateDto} from "../Entities/Dtos";
import Template from "../Entities/Template";
import api from "../Services/Api";

class TemplateListStore {
    @observable data: Template[] = [];
    @observable selected?: Template;
    @observable parentCategories: Category[] = [];
    @observable isLoading: boolean = false;
    @observable isLoaded: boolean = false;
    @observable serviceFieldsIsVisible: boolean = false;

    @observable sourceSwapItem?: Template;
    @observable destinationSwapItem?: Template;
    @observable searchText: string = '';
    
    async load() {
        this.isLoading = true;
        const result = await api.get('template/list');
        await runInAction(async () => {
            if (result.success) {
                this.selected = undefined;
                this.data = this.deserialize(result.data.templates);
                this.sort();
                if (this.data.length > 0) {
                    this.select(this.data[0]);
                }
                await this.loadParentCategories();
            }
            this.isLoading = false;
            this.isLoaded = true;
        })
    }
    async create() {
        this.isLoading = true;
        const result = await api.post('template/create', {});
        if (result.success) {
            await this.load();
        }
    }
    select(template: Template) {
        if (template === this.selected) {
            return;
        }

        this.selected = template;
        void this.selected.loadBody();
    }
    async loadParentCategories() {
        const result = await api.get('category/parent/list?sourceCode=avito');
        if (result.success) {
            this.parentCategories = result.data.categories;
        }
    }
    @computed get hasNew(): boolean {
        if (this.data.length === 0) {
            return false;
        }

        return this.data.some((template) => template.isNew);
    }
    changeSearchText(value: string){
        this.searchText = value;
    }
    startSwapping(template: Template) {
        this.sourceSwapItem = template;
    }
    finishSwapping(template: Template) {
        this.destinationSwapItem = template;
    }
    swap() {
        if (!this.sourceSwapItem || !this.destinationSwapItem || this.sourceSwapItem === this.destinationSwapItem) {
            this.sourceSwapItem = undefined;
            this.destinationSwapItem = undefined;
            return;
        }
        const sourcePriority = this.sourceSwapItem.priority;
        void this.sourceSwapItem.changePriority(this.destinationSwapItem.priority);
        void this.destinationSwapItem.changePriority(sourcePriority);
        this.sourceSwapItem = undefined;
        this.destinationSwapItem = undefined;
        this.sort();
    }
    sort() {
        this.data = this.data.sort((a, b) => {
            return b.priority - a.priority;
        });
    }
    showServiceFields() {
        this.serviceFieldsIsVisible = true;
    }
    private deserialize(templates: TemplateDto[]): Template[] {
        return templates.map(s => new Template(s.id, s.name, s.priority));
    }
}

const templateListStore = new TemplateListStore();
export default templateListStore;