import {observer} from 'mobx-react-lite'
import React, {useEffect} from 'react'
import {Grid} from 'semantic-ui-react'
import TemplateMappingItem from './TemplateMappingItem'
import productFolderListStore from "../../Stores/ProductFolderListStore";

function TemplateMappingList() {
    useEffect(() => {
        productFolderListStore.load();
    }, []);

    return (
        <Grid>
            {productFolderListStore.folders.map((x) => x.templateName && <TemplateMappingItem item={x} key={x.id}/>)}
        </Grid>
    )
}

export default observer(TemplateMappingList);
