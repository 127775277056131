import React from "react";
import {Icon, List} from "semantic-ui-react";
import 'moment/locale/ru';
import ChatStorage from "../../Stores/Messenger/ChatStorage";
import {observer} from "mobx-react-lite";
import timeUtils from "../../Services/TimeUtils";

function ChatListItem(props: { chat: ChatStorage, onSelect: (chat: ChatStorage) => void, isActive: boolean }) {
    const {chat, onSelect, isActive} = props;
    return <List.Item onClick={() => onSelect(chat)} active={isActive}>
        <List.Content floated='right'>
            <p>{timeUtils.createShortStr(chat.lastMessage.createdOn)}</p>
            {chat.notificationCount > 0 &&
                <div>
                    <Icon color={'red'} name='bell outline'/>{chat.notificationCount}
                </div>
            }
        </List.Content>
        
        <div style={{
                float: 'left', 
                width: '80px', 
                height: '80px', 
                backgroundImage: 'url('+chat.ad.imageUrl+')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                borderRadius: '6px',
                marginRight: '10px'}}>
        </div>
        <List.Content>
            <List.Header as={'h4'}>{chat.client.name}</List.Header>
            <List.Description>
                {CutText(chat.ad.name, 50)}<br/>
                {"Цена: " + chat.ad.price}
            </List.Description>
            <p>{CutText(chat.lastMessage.text, 35)}</p>
        </List.Content>
    </List.Item>
}

function CutText(text: string, limit: number) {
    text = text.trim();
    if( text.length <= limit) return text;

    text = text.slice(0, limit);

    return text.trim() + "...";
}

export default observer(ChatListItem);