import React from 'react'
import {Button, Grid, Icon, Label, Segment} from 'semantic-ui-react'
import skladStore from "../../Stores/SkladStore";

const PriceInfo = () => (
    <Grid>
        <Grid.Column>
            <Segment raised>
                <Label as='a' color='red' ribbon>
                    Повышение цены
                </Label>
                <br/><br/>
                В связи с удорожанием содержания инфраструктуры, а также затрат на поддержку и доработку приложения,
                хотим сообщить вам об увеличении стоимости оплаты прилжения.
                <br/>
                С <b>01.03.2022г.</b> Стоимость приложения составит <b>3000</b> рублей в месяц.<br/>
                <br/>
                Мы понимаем что повышение цены это всегда не очень хорошо с точки зрения конечного потребителя,
                но это вынужденная мера.<br/>
                Мы по прежнему хотим расти и развивать наш сервис, чтобы помогать предпринимателям более продуктивно
                вести их бизнес,
                а также избавить от повседневной рутинной задачи по размещению и актуализации обявлений в ручном режиме.
                <br/><br/>Вот уже полтора года существует наш сервис, за это время мы провели немало обновлений, в которых вы
                принимали непосредственное участие.
                <br/>Мы чутко прислушиваемся к вашим пожеланиям относительно работы сервиса, и благодаря этому на данный
                момент можно очень гибко настроить параметры выгрузки.
                <br/>Отдельная благодарность организациям, которые принимают участие в тестовых релизах.
                <br/>Спасибо что вы все с нами, надеемся на понимание.<br/><Icon color='red' name='heart'/>
                <br/><br/><b>С Уважением, ваш помощник UnitXML.</b>
            </Segment>
            <Button primary={true} onClick={() => skladStore.showPriceInformation = false}>Ознакомлен</Button>
        </Grid.Column>
    </Grid>
)

export default PriceInfo