import React from "react";
import {observer} from "mobx-react-lite";
import {Button, Form, FormField, Grid, Segment} from "semantic-ui-react";
import stockWebhookTesterStore from "./StockWebhookTesterStore";

function StockWebhookTester() {
    if (!stockWebhookTesterStore.visible) {
        return <></>
    }

    return <Grid>
        <Grid.Column width={'7'}>
            <Segment>
                <Form>
                    <FormField>
                        <label>Id</label>
                        <input value={stockWebhookTesterStore.id}
                               onChange={e => stockWebhookTesterStore.setId(e.target.value)}/>
                    </FormField>
                    <Button onClick={() => stockWebhookTesterStore.trigger()} color={'teal'}>Trigger</Button>
                    <Button floated={'right'} onClick={() => stockWebhookTesterStore.hide()}>Close</Button>
                </Form>
            </Segment>
        </Grid.Column>
    </Grid>
}

export default observer(StockWebhookTester);