class Utils {
    beautifyJson(input: string): string {
        return JSON.stringify(JSON.parse(input), null, 2)
    }

    getLocalizedDateString(dateStr: string) {
        const date = Date.parse(dateStr);

        return new Intl.DateTimeFormat("ru-RU", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric"
        }).format(date);
    }
}

const utils = new Utils();
export default utils;