import {Grid, Input, LabelProps, SemanticShorthandItem} from "semantic-ui-react";
import React, {useState} from "react";
import FieldName from "../Shared/FieldName";
import FieldValue from "../Shared/FieldValue";
import copy from "copy-to-clipboard";
import {SemanticWIDTHS} from "semantic-ui-react/dist/commonjs/generic";

function TextField(props: {
    name: string
    value: string | number
    onChange?: (value: string) => void
    onChangeNumber?: (value: number) => void
    onSave?: () => void
    autoFocus?: boolean
    readOnly?: boolean
    copied?: boolean
    required?: boolean,
    helpUrl?: string,
    type?: string,
    children?: React.ReactNode,
    valueWidth?: SemanticWIDTHS,
    nameWidth?: SemanticWIDTHS,
    label?: SemanticShorthandItem<LabelProps>,
    labelPosition?: 'left' | 'right' | 'left corner' | 'right corner'
}) {
    const [showCopiedMessage, setShowCopiedMessage] = useState(false);
    const {
        name, value, onChange, onChangeNumber,
        onSave, autoFocus,
        readOnly, copied,
        required, helpUrl, type,
        children, valueWidth, nameWidth,
        label, labelPosition
    } = props;
    let action: any;
    if (helpUrl) {
        action = {icon: 'help', as: 'a', 'href': helpUrl, target: '_blank'};
    }
    if (copied) {
        action = {
            icon: 'copy', onClick: () => {
                if (!showCopiedMessage) {
                    copy(value as string);
                    setShowCopiedMessage(true);
                    setTimeout(() => {
                        setShowCopiedMessage(false);
                    }, 500)
                }
            }
        }
    }
    return <Grid.Row>
        <FieldName name={name} required={required} width={nameWidth}/>
        <FieldValue width={valueWidth}>
            <Input value={showCopiedMessage ? 'Данные скопированы!' : value}
                   type={type}
                   fluid
                   readOnly={readOnly}
                   label={label}
                   labelPosition={labelPosition}
                   autoFocus={autoFocus}
                   action={action}
                   onKeyDown={(e: any) => {
                       if ((e.ctrlKey || e.metaKey) && e.key === 'Enter' && onSave) {
                           onSave();
                       }
                   }}
                   onChange={(e) => {
                       if (onChange) {
                           onChange(e.target.value)
                       }
                       
                       if (onChangeNumber) {
                           onChangeNumber(Number(e.target.value ?? 0));
                       }
                   }}
            />
            {children}
        </FieldValue>
    </Grid.Row>
}

export default TextField;
