import { observable } from "mobx";
import api from "../Services/Api";
import productFolderListStore from "../Stores/ProductFolderListStore";

export class ProductFolder {
    id: string;
    @observable templateName: string;
    @observable name: string;

    constructor(id: string, name: string, templateName: string) {
        this.id = id;
        this.templateName = templateName;
        this.name = name;
    }

    async removeTemplate() {
        const result = await api.post('productFolder/removeTemplate', {
            productFolderId: this.id
        });
        if (result.success) {
            productFolderListStore.load();
        }
    }
}