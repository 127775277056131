import React from 'react';
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { Segment, Sidebar } from 'semantic-ui-react';
import useResizeAware from 'react-resize-aware';

import Header from './Components/Header/Header';
import Auth from './Pages/Auth'
import Templates from './Pages/Templates/Templates';
import Dashboard from './Pages/Dashboard/Dashboard';
import ErrorPortal from './Components/ErrorPortal/ErrorPortal';
import SuccessPortal from './Components/SuccessPortal';
import authStore from "./Stores/AuthStore";
import TemplateMapping from "./Pages/TemplateMapping/TemplateMapping";
import UnloadsSettingsPage from "./Pages/Unloads/UnloadsSettingsPage";
import StoresSettingsPage from "./Pages/Stores/StoresSettingsPage";
import AvitoAccountsSettingPage from "./Pages/Avito/AvitoAccountsSettingPage";
import MessengerPage from "./Pages/Messenger/MessengerPage";
import HelpPage from "./Pages/Help/HelpPage";
import OrdersPage from "./Pages/Orders/OrdersPage";
import OrderPage from "./Pages/Orders/Order/OrderPage";

let minHeight = 0;

function MainRouter() {
    const [resizeListener, sizes] = useResizeAware();
    const sendExpand = () => {
        if (sizes.height && typeof window.parent !== 'undefined' && window.parent && sizes.height > window.innerHeight) {
            let h = sizes.height;
            if (sizes.height < minHeight) {
                h = minHeight;
            }
            const sendObject = {
                height: h
            };
            window.parent.postMessage(sendObject, '*');
        }
    }
    sendExpand();
    if (window.innerHeight !== 0 && minHeight === 0) {
        minHeight = window.innerHeight - 5;
    }
    return (
        <>
            <Sidebar.Pushable as={Segment} style={{ overflow: authStore.isAdmin ? 'unset': 'hidden' }}>
                {authStore.loggedIn && <Header />}
                <Sidebar.Pusher>
                    {resizeListener}
                    <Segment style={{ 'border': 'unset', 'boxShadow': 'unset' }}>
                        <ErrorPortal />
                        <SuccessPortal />
                        <Switch>
                            <Route path="/auth">
                                {authStore.loggedIn ? <Redirect to="/" /> : <Auth />}
                            </Route>
                            <Route path="/" exact>
                                {authStore.loggedIn ? <Redirect to="/home" /> : <Redirect to="/auth" />}
                            </Route>
                            <Route path="/home">
                                {authStore.loggedIn ? <Dashboard /> : <Redirect to="/auth" />}
                            </Route>
                            <Route path="/templates">
                                {authStore.loggedIn ? <Templates /> : <Redirect to="/auth" />}
                            </Route>
                            <Route path="/templateMapping">
                                {authStore.loggedIn ? <TemplateMapping /> : <Redirect to="/auth" />}
                            </Route>
                            <Route path="/stores">
                                {authStore.loggedIn ? <StoresSettingsPage /> : <Redirect to="/auth" />}
                            </Route>
                            <Route path="/unloads">
                                {authStore.loggedIn ? <UnloadsSettingsPage /> : <Redirect to="/auth" />}
                            </Route>
                            <Route path="/avito">
                                {authStore.loggedIn ? <AvitoAccountsSettingPage /> : <Redirect to="/auth" />}
                            </Route>
                            <Route path="/messenger">
                                {authStore.loggedIn ? <MessengerPage /> : <Redirect to="/auth" />}
                            </Route>
                            <Route path="/orders" exact>
                                {authStore.loggedIn ? <OrdersPage /> : <Redirect to="/auth" />}
                            </Route>
                            <Route path={`/orders/:id`}>
                                {authStore.loggedIn ? <OrderPage /> : <Redirect to="/auth" />}
                            </Route>
                            <Route path="/help">
                                {authStore.loggedIn ? <HelpPage /> : <Redirect to="/auth" />}
                            </Route>
                        </Switch>
                    </Segment>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </>
    );
}

export default observer(MainRouter);
