import moment from "moment/moment";

moment.locale("ru")
const timeUtils = {
    createShortStr(date: Date): string {
        const now = moment();
        const m = moment(date);
        if (m.day() === now.day() && m.month() === now.month() && m.year() === now.year()) {
            return m.format("HH:mm");
        }
        
        if (m.year() === now.year()){
            return m.format("D MMM HH:mm");
        }
        
        return m.format("D MMM YYYY HH:mm");
    }
};

export default timeUtils;