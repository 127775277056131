import {observable, runInAction} from "mobx";
import templateListStore from "../Stores/TemplateListStore";
import api from "../Services/Api";
import Field from "./TemplateFieldValue";
import {Category} from "./Dtos";

export default class Template {
    id: string;
    @observable name: string;
    @observable priority: number;
    @observable category?: string;
    @observable fields: Field[] = [];
    @observable requiredPrice: boolean = false;
    @observable isNew: boolean = false;
    @observable parentCategory?: string;

    @observable categories: Category[] = [];
    @observable isEditStarted: boolean = false;
    @observable isSaving: boolean = false;
    @observable isChanged: boolean = false;
    @observable isLoadingBody: boolean = false;
    @observable isLoadingFields: boolean = false;
    @observable historyIsVisible: boolean = false;

    constructor(id: string, name: string, priority: number) {
        this.id = id;
        this.name = name;
        this.priority = priority;
    }

    get isValid() {
        return this.fields.filter((field) => field.isRequired && field.value.trim() === '').length === 0;
    }

    get showCategory() {
        return (this.parentCategory !== undefined && this.parentCategory !== '2b8f7bbd-4839-496d-bfe2-27d83c33e962') as boolean;
    }

    async save() {
        this.isSaving = true;
        const result = await api.post("template/save", {
            id: this.id,
            name: this.name,
            category: this.category,
            fields: this.fields,
            requiredPrice: this.requiredPrice
        });
        if (result.success) {
            runInAction(() => {
                this.isChanged = false;
                this.fields.forEach(f=> f.hideHistory());
                this.hideHistory();
                void this.loadBody();
            });
        }
        this.isSaving = false;
    }

    async copy() {
        const result = await api.post("template/copy", {
            id: this.id
        });
        if (result.success) {
            await templateListStore.load();
        }
    }

    async saveName() {
        if (!this.name) return;
        const result = await api.post("template/saveName", {
            id: this.id,
            name: this.name
        });
        if (result.success) {
            this.isEditStarted = false;
        }
    }
    async changePriority(value: number){
        this.priority = value;
        await api.post("template/changePriority", {
            id: this.id,
            priority: this.priority
        });
    }
    async delete() {
        const result = await api.post("template/delete", {
            id: this.id
        });
        if (result.success) {
            await templateListStore.load();
        }
    }

    async loadCategories() {
        const result = await api.get('category/list?parentId=' + this.parentCategory);
        if (result.success) {
            this.categories = result.data.categories;
        }
    }

    async changeCategory(categoryId: any) {
        if (!categoryId) return;
        this.category = categoryId;
        this.isChanged = true;
        await this.loadFields();
    }

    async changeParentCategory(categoryId: any) {
        this.parentCategory = categoryId;
        await this.loadCategories();
    }
    
    changeRequiredPrice(value: boolean){
        this.isChanged = true;
        this.requiredPrice = value;
    }

    async loadFields() {
        this.isLoadingFields = true;

        const result = await api.get('template/fields?category=' + this.category);
        if (result.success) {
            const oldFields = this.fields
            const hasOldFields = oldFields && oldFields.length > 0;
            this.fields = result.data.fields.map((newField: any) => {
               const id = newField.id;
               const showMultiplyModeEditor = newField.showMultiplyModeEditor;
               let isMultiply = newField.isMultiply;
               let value = '';
               let templateFieldId = null;
               if (hasOldFields) {
                   const matchedFields = oldFields.filter(oldField => oldField.id === id);
                   if (matchedFields && matchedFields.length > 0) {
                       const matchedField = matchedFields[0];
                       value = matchedField.value;
                       isMultiply = matchedField.isMultiply;
                       templateFieldId = matchedField.templateFieldId;
                   }
               }

                return new Field(id, newField.name, templateFieldId, newField.description, newField.code, value, newField.isRequired, newField.values, isMultiply, showMultiplyModeEditor);
            });
        }

        this.isLoadingFields = false;
    }

    async loadBody() {
        this.fields = [];
        this.isLoadingBody = true;

        const result = await api.get(`template/${this.id}`);
        if (result.success) {
            this.category = result.data.category;
            this.fields = result.data.fields.map((f: any) => new Field(f.id, f.name, f.templateFieldId, f.description, f.code, f.value, f.isRequired, f.values, f.isMultiply, f.showMultiplyModeEditor));
            this.isNew = result.data.isNew;
            this.parentCategory = result.data.parentCategory;
            this.requiredPrice = result.data.requiredPrice;
        }

        if (this.showCategory) {
            if (this.categories.length === 0) {
                await this.loadCategories();
            }

            await this.loadFields();
        }

        this.isLoadingBody = false;
    }

    showHistory(){
        this.historyIsVisible = true;
    }

    hideHistory(){
        this.historyIsVisible = false;
    }
}
