import {observable, runInAction} from "mobx";
import api from "../Services/Api";
import {FileDto, IntegrationLog} from "../Entities/Dtos";
import websocketEventConsumer from "../Services/WebsocketEventConsumer";

interface IStore {
    load: () => Promise<void>;
    lastExecution: string;
    nextExecution: string;
    downloadUrl: string
    lastJobState: string;
    lastJobStartExecuting: string;
    productCount: number;
    activeFoldersCount: number;
    activeStoresCount: number;
    isLoading: boolean;
    integrations: IntegrationLog[];
    files: FileDto[];
    logUrl?: string;
    userId: string;
    tariffName: string;
    jobIsRunning: boolean;
    isLoaded: boolean;
    messengerIsEnabled: boolean;
    orderIntegrationIsEnabled: boolean;
    showOrderIntegrationSettings: boolean;
}

const defaultValue: string = "Нет данных";

const store = observable<IStore>({
    userId: "",
    tariffName: "",
    files: [],
    downloadUrl: "",
    integrations: [],
    productCount: 0,
    activeFoldersCount: 0,
    activeStoresCount: 0,
    lastExecution: defaultValue,
    nextExecution: defaultValue,
    lastJobState: defaultValue,
    isLoading: false,
    jobIsRunning: false,
    lastJobStartExecuting: '-',
    isLoaded: false,
    messengerIsEnabled: false,
    orderIntegrationIsEnabled: false,
    showOrderIntegrationSettings: false,
    async load() {
        const result = await api.get("profile/info");
        this.isLoading = true;
        if (result.success) {
            runInAction(() => {
                this.lastJobState = result.data.lastJobState ?? defaultValue;
                this.nextExecution = result.data.nextExecution ?? defaultValue;
                this.lastExecution = result.data.lastExecution ?? defaultValue;
                this.activeStoresCount = result.data.activeStoresCount;
                this.activeFoldersCount = result.data.activeFoldersCount;
                this.productCount = result.data.productCount;
                this.integrations = result.data.integrations;
                this.files = result.data.files;
                this.logUrl = result.data.logUrl;
                this.userId = result.data.userId;
                this.tariffName = result.data.tariffName;
                this.isLoading = false;
                this.lastJobStartExecuting = result.data.lastJobStartExecuting;
                this.jobIsRunning = result.data.jobIsRunning;
                this.downloadUrl = result.data.downloadUrl;
                this.messengerIsEnabled = result.data.messengerIsEnabled;
                this.orderIntegrationIsEnabled = result.data.orderIntegrationIsEnabled;
                this.showOrderIntegrationSettings = result.data.showOrderIntegrationSettings;
                this.isLoaded = true;
            });
            
            await websocketEventConsumer.consumeToUserEvents(this.userId);
        }
    }
});

export default store;