import api from "../Services/Api";
import {observable} from "mobx";

class StockWebhookTesterStore {
    @observable id: string = "";
    @observable visible: boolean = false;

    setId(value: string) {
        this.id = value;
    }

    async trigger() {
        await api.post('admin/stockWebhookHandler.trigger', {
            id: this.id
        });
    }

    show() {
        this.visible = true;
    }

    hide() {
        this.visible = false;
        this.id = "";
    }
}

export default new StockWebhookTesterStore();