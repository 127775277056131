import React from 'react';
import { Icon, Popup, Table } from 'semantic-ui-react';
import errorConverter from '../../../Services/ErrorConverter';
import './Style.css';

interface IRowProps {
    dateTime: string;
    message?: string;
    isSuccess?: boolean;
    inProgress?: boolean;
    productCount: number;
}

export function LogRow(props: IRowProps) {
    const {isSuccess, inProgress, productCount } = props;
    const positive = isSuccess || inProgress;
    return (
        <Table.Row className={positive ? "positive-row" : "negative-row"}>
            <Table.Cell>{props.dateTime ?? '-'}</Table.Cell>
            <Table.Cell>
                {positive ?
                    <div>
                        <Icon  loading={inProgress} name={inProgress ? 'spinner' : 'checkmark'} />
                    </div>
                    :
                    <Popup
                        trigger={
                            <div style={{ 'cursor': 'pointer' }}>
                                <Icon name='close' />
                            </div>
                        }
                        content={errorConverter.getCaptionFormCode(props.message!)}
                        position='right center'
                    />
                }
            </Table.Cell>
            <Table.Cell>{productCount}</Table.Cell>
        </Table.Row>
    );
}