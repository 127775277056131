import {observer} from 'mobx-react-lite';
import React from 'react'
import {Header, List} from 'semantic-ui-react';
import StoreItem from "./StoreItem";
import skladStore from "../../../../Stores/SkladStore";

function StoreList() {
    return (
        <>
            <Header as='h4' textAlign='center' style={{marginTop: '20px'}}>
                Выберите нужные склады с товарами
            </Header>
            <List relaxed divided style={{'textAlign': 'left', maxHeight: '200px', overflow: 'auto', marginTop: '5px'}}>
                {skladStore.stores.map((store) => <StoreItem key={store.id} store={store}/>)}
            </List>
        </>
    );
}

export default observer(StoreList);
