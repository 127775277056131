import {observable} from "mobx";
import api from "../Services/Api";
import StoreFieldValue from "../Entities/FieldValue";
import UnloadStore from "../Entities/UnloadStore";
import unloadsSettingsStorage from "./UnloadsSettingsStorage";
import IHasIdAndName from "../Entities/IHasIdAndName";

export default class UnloadSettingsStorage implements IHasIdAndName {
    id: string;
    @observable name: string;
    @observable priceFieldId?: string;
    @observable descriptionFieldId?: string;
    @observable fieldValues: StoreFieldValue[];
    @observable stores: UnloadStore[];
    @observable saving: boolean = false;
    @observable deleting: boolean = false;
    @observable useProductFlag: boolean = false;
    private hasPrivateChanges: boolean = false;
    isMain: boolean;
    isNew: boolean = false;

    constructor(name: string, id?: string, fieldValues?: StoreFieldValue[], stores?: UnloadStore[], priceFieldId?: string, descriptionFieldId?: string, useProductFlag: boolean = false) {
        this.isNew = !id;
        this.id = id ?? 'new';
        this.name = name;
        this.fieldValues = fieldValues ?? [];
        this.priceFieldId = priceFieldId;
        this.descriptionFieldId = descriptionFieldId;
        this.stores = stores ?? [];
        this.isMain = name === 'main';
        this.useProductFlag = useProductFlag;
    }

    async save() {
        if (this.saving || !this.hasChanges) return;

        this.changeSaving(true);
        try {
            const result = await api.post(this.getSaveUrl, {
                id: this.id,
                name: this.name,
                useProductFlag: this.useProductFlag,
                fieldValues: this.fieldValues
                    .filter(x => x.hasChanges)
                    .map(x => ({id: x.id, fieldId: x.fieldId, value: x.value})),
                stores: this.stores
                    .filter(x => x.hasChanges)
                    .map(x => ({id: x.id, storeId: x.storeId, isActive: x.isActive})),
                priceFieldId: this.priceFieldId === '' ? null : this.priceFieldId,
                descriptionFieldId: this.descriptionFieldId === '' ? null : this.descriptionFieldId
            });
            if (result.success) {
                await unloadsSettingsStorage.load();
            }
        } finally {
            this.changeSaving(false);
        }
    }

    get xmlUrl(): string{
        if (this.isMain) {
            return unloadsSettingsStorage.xmlUrl;
        }

        return unloadsSettingsStorage.xmlUrl + '/' + this.name;
    }

    get stockUrl(): string{
        if (this.isMain) {
            return unloadsSettingsStorage.stockUrl;
        }

        return unloadsSettingsStorage.stockUrl + '/' + this.name;
    }

    async delete() {
        this.deleting = true;
        try {
            const result = await api.post("settings/unload.delete", {
                id: this.id
            })

            if (result.success) {
                await unloadsSettingsStorage.load();
            }
        } finally {
            this.deleting = false;
        }
    }

    get hasChanges() {
        return this.name && this.hasPrivateChanges
            || this.fieldValues.some(x => x.hasChanges)
            || this.stores.some(x => x.hasChanges);
    }

    private changeSaving(value: boolean) {
        this.saving = value;
    }

    private get getSaveUrl(): string {
        if (this.isNew) {
            return 'settings/unload.create';
        }

        return 'settings/unload.save';
    }

    getOrCreateFieldValue(fieldId: string): StoreFieldValue {
        const existsFieldValue = this.getFieldValue(fieldId);
        if (existsFieldValue) {
            return existsFieldValue;
        }
        const fieldValue = new StoreFieldValue(fieldId);
        this.fieldValues.push(fieldValue);

        return fieldValue;
    }

    getOrCreateUnloadStore(storeId: string): UnloadStore {
        const existsStore = this.getUnloadStore(storeId);
        if (existsStore) {
            return existsStore;
        }

        const unloadStore = new UnloadStore(storeId, false);
        this.stores.push(unloadStore);

        return unloadStore;
    }

    changePriceFieldId(value?: string) {
        this.priceFieldId = value;
        this.hasPrivateChanges = true;
    }

    changeDescriptionFileId(value?: string) {
        this.descriptionFieldId = value;
        this.hasPrivateChanges = true;
    }

    changeName(value: string) {
        if (this.isMain) {
            return;
        }
        this.name = value.replace(/[^a-zA-Z0-9а-яА-я-_]/g, '');
        this.hasPrivateChanges = true;
    }

    changeUseProductFlag(value: boolean) {
        if (this.isMain) {
            return;
        }
        this.useProductFlag = value;
        this.hasPrivateChanges = true;
    }

    private getFieldValue(fieldId: string): StoreFieldValue {
        return this.fieldValues.filter(x => x.fieldId === fieldId)[0];
    }

    private getUnloadStore(storeId: string): UnloadStore {
        return this.stores.filter(x => x.storeId === storeId)[0];
    }
}
