import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css'
import 'antd/dist/antd.css';
import './index.css';
import MainRouter from './MainRouter';
import {
    BrowserRouter as Router, Route, Switch
} from "react-router-dom";
import AdminRouter, {routeName} from "./Admin/AdminRouter";

ReactDOM.render(
    <Router>
        <Switch>
            <Route path={"/" + routeName}>
                <AdminRouter/>
            </Route>
            <Route>
                <MainRouter/>
            </Route>
        </Switch>
    </Router>,
    document.getElementById('root')
);