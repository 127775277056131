import {observer} from "mobx-react-lite";
import UnloadSettingsStorage from "../../Stores/UnloadSettingsStorage";
import React from 'react'
import {InformationPanelItem} from "../Dashboard/InformationPanelItem";
import {Button, Grid, GridRow} from "semantic-ui-react";
import unloadsSettingsStorage from "../../Stores/UnloadsSettingsStorage";
import NewUnloadField from "./NewUnloadField";
import skladStore from "../../Stores/SkladStore";
import FieldWithFieldIdSelector from "../Shared/FieldWithFieldIdSelector";
import FieldValueRaw from "../Shared/FieldValueRaw";
import FieldValue from "../Shared/FieldValue";
import TextField from "../Shared/TextField";
import CheckboxWithTip from "../Dashboard/CheckboxWithTip";
import authStore from "../../Stores/AuthStore";

function UnloadComponent(props: { unload: UnloadSettingsStorage }) {
    const {unload} = props;
    return <InformationPanelItem header={'Параметры XML файла'}>
        <Grid>
            <TextField name={'Название XML файла'}
                       value={unload.name}
                       onSave={() => unload.save()}
                       autoFocus={unload.isNew}
                       required
                       onChange={value => unload.changeName(value)}>
            </TextField>
            <TextField name={'Ссылка на XML файл с объявлениями'}
                       value={unload.xmlUrl}
                       copied
                       readOnly/>
            <TextField name={'Ссылка на XML файл с остатками'}
                       value={unload.stockUrl}
                       copied
                       readOnly/>
            <FieldWithFieldIdSelector name={'Цена'} value={unload.priceFieldId}
                                      fields={skladStore.salePrices}
                                      placeholder={'Выберите цену для товаров данного XML файла'}
                                      tip={'Поле цены для данного XML файла'}
                                      onChange={value => unload.changePriceFieldId(value)}/>
            <FieldWithFieldIdSelector name={'Описание'} value={unload.descriptionFieldId}
                                      fields={skladStore.customFields}
                                      placeholder={'Выберите описание для товаров данного XML файла'}
                                      tip={'Поле описания для данного XML файла'}
                                      onChange={value => unload.changeDescriptionFileId(value)}/>
            {unloadsSettingsStorage.fields
                .map(field => <FieldValueRaw key={field.id}
                                             field={field}
                                             fieldValue={unload.getOrCreateFieldValue(field.id)}
                                             onSave={() => unload.save()}/>)}
            <GridRow>
                <NewUnloadField/>
                <FieldValue>
                    <Button color={'teal'} floated={'right'}
                            onClick={() => unload.save()}
                            disabled={!unload.hasChanges}
                            loading={unload.saving}>Сохранить</Button>
                </FieldValue>
            </GridRow>

        </Grid>
    </InformationPanelItem>
}

export default observer(UnloadComponent);
