import {observable} from "mobx";

export default class Store {
    id: string;
    name: string;
    @observable isActive: boolean;
    constructor(id: string, name: string, isActive: boolean) {
        this.id = id;
        this.name = name;
        this.isActive = isActive;
    }

    changeActive(value: boolean) {
        this.isActive = value;
    }
}
