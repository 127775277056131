import axios from 'axios';
import authStore from "../Stores/AuthStore";
import errorStore from "../Stores/ErrorStore";

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401 || error.response.status === 403) {
        authStore.logOut();
    }
    if (error.response.status === 502) {
        errorStore.add("Сервис не доступен :(");
    }
    return Promise.reject(error);
}
);

const api = {
    async post(url: string, data: any): Promise<any> {
        const result = await axios.post(`/api/${url}`, data, {
            headers: { Authorization: `Bearer ${authStore.token}` }
        });

        if (!result.data.success) {
            errorStore.add(result.data.error);
        }

        return result.data;
    },
    async get(url: string): Promise<any> {
        const result = await axios.get(`/api/${url}`, {
            headers: { Authorization: `Bearer ${authStore.token}` }
        });

        if (!result.data.success && result.data.error) {
            errorStore.add(result.data.error);
        }

        return result.data;
    }
}

export default api;