import {Button, Input, Label} from "semantic-ui-react";
import React from "react";
import {observer} from "mobx-react-lite";
import FieldEditorStore from "../../Stores/FieldEditorStore";

function FieldEditor(props: { store: FieldEditorStore }) {
    const {store} = props;

    return <>
        <Input fluid action autoFocus={true} onBlur={() => store.cancel()}>
            <input
                readOnly={store.isSaving}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        void store.save();
                    }
                    if (e.key === 'Escape') {
                        store.cancel();
                    }
                }}
                value={store.name ?? ''}
                onChange={(e) => store.changeName(e.target.value)}/>
            {store.name && store.hasChanges && <Button icon={'save'}
                                                       onMouseDown={(e: any) => {
                                                           e.preventDefault();
                                                           e.stopPropagation()
                                                       }}
                                                       loading={store.isSaving}
                                                       onClick={() => store.save()}/>}
            <Button icon={'cancel'}
                    onClick={() => store.cancel()}/>
        </Input>
        <Label style={{marginTop: '5px'}} color={'teal'}>Для удаления напишите "удалить"</Label>
    </>
}

export default observer(FieldEditor);
