import React from 'react'
import CategorySelect from './CategorySelect';
import {observer} from 'mobx-react-lite';
import {InformationPanelItem} from '../../Dashboard/InformationPanelItem';
import '../../Templates/Style.css';
import TemplateName from './TemplateName';
import Template from "../../../Entities/Template";
import Field from "./Fields/Field";
import {Button, Grid, Icon} from "semantic-ui-react";
import templateListStore from "../../../Stores/TemplateListStore";
import FieldWithCheckbox from "../../Shared/FieldWithCheckbox";
import TemplateSaveButton from "./TemplateSaveButton";

const serviceFieldCodes = ['ImageUrlListNameInTemplate', 'ImageRangeFieldNameInTemplate'];

function TemplateComponent(props: { template: Template }) {
    const {template} = props;
    return (
        <>
            <InformationPanelItem loading={template.isLoadingBody}>
                <TemplateName template={template}/>
                <CategorySelect template={template}/>
            </InformationPanelItem>
            {template.fields && template.fields.length > 0 &&
                <>
                    <InformationPanelItem loading={template.isLoadingFields}>
                        <Grid>
                            {template.fields.filter(x => !serviceFieldCodes.includes(x.code)).map((field) => {
                                return <Field showCode={true} template={template} key={field.id + template.id}
                                              field={field}/>
                            })}
                        </Grid>
                    </InformationPanelItem>
                    {templateListStore.serviceFieldsIsVisible &&
                        <InformationPanelItem loading={template.isLoadingFields} header={'Дополнительные настройки'}>
                            <Grid>
                                {template.fields.filter(x => serviceFieldCodes.includes(x.code)).map((field) => {
                                    return <Field tip={field.description} template={template}
                                                  key={field.id + template.id}
                                                  field={field}/>
                                })}
                                <FieldWithCheckbox name={"Считать цену обязательной"}
                                                   tip={"Если цена 0 - товар выгружаться не будет"}
                                                   visible={!template.isNew}
                                                   value={template.requiredPrice}
                                                   onChange={value => template.changeRequiredPrice(value)}
                                />
                            </Grid>
                        </InformationPanelItem>
                    }
                    <TemplateSaveButton template={template}/>
                    {!templateListStore.serviceFieldsIsVisible && 
                        <Button floated={'right'} onClick={() => templateListStore.showServiceFields()} icon>
                            <Icon name='setting'/>
                        </Button>
                    }
                    <Button floated={'right'} icon onClick={() => 
                        template.historyIsVisible ? template.hideHistory() : template.showHistory()
                    }>
                        <Icon name={template.historyIsVisible ? 'hide' : 'history'}/>
                    </Button>
                </>
            }
        </>
    )
}

export default observer(TemplateComponent);