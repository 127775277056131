import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Grid} from "semantic-ui-react";
import unloadsSettingsStorage from "../../Stores/UnloadsSettingsStorage";
import UnloadList from "./UnloadList";
import UnloadStoreList from "./UnloadStoreList";
import UnloadComponent from "./UnloadComponent";
import ConfirmDelete from "../Shared/ConfirmDelete";

function UnloadsSettingsPage() {
    useEffect(() => {
        void unloadsSettingsStorage.load();
    }, []);


    return <Grid>
        <ConfirmDelete isOpen={unloadsSettingsStorage.deletingUnload !== undefined}
                       name={unloadsSettingsStorage.deletingUnload?.name}
                       isLoading={unloadsSettingsStorage.deletingUnload?.deleting}
                       onConfirm={() => void unloadsSettingsStorage.deletingUnload?.delete()}
                       onCancel={() => unloadsSettingsStorage.cancelDelete()}
        />
        <Grid.Column width={3}>
            <UnloadList/>
            {unloadsSettingsStorage.currentUnload && !unloadsSettingsStorage.currentUnload.isMain &&
                <UnloadStoreList unload={unloadsSettingsStorage.currentUnload}/>}
        </Grid.Column>
        <Grid.Column width={10}>
            {unloadsSettingsStorage.currentUnload && <UnloadComponent unload={unloadsSettingsStorage.currentUnload}/>}
        </Grid.Column>
    </Grid>
}

export default observer(UnloadsSettingsPage);
