import {Checkbox, List, Popup} from "semantic-ui-react";
import React from "react";
import UnloadStore from "../../Entities/UnloadStore";
import {observer} from "mobx-react-lite";
import skladStore from "../../Stores/SkladStore";

function UnloadStoreRaw(props: { unloadStore: UnloadStore, storeName: string }) {
    const {unloadStore, storeName} = props;
    const isActiveInMainSettings = skladStore.stores.filter(x => x.isActive).some(x => x.id === unloadStore.storeId);
    return <List.Item>
        {isActiveInMainSettings
            ? <Checkbox label={storeName} checked={unloadStore.isActive}
                        onChange={(_, data) => unloadStore.changeActive(data.checked!)}/>
            : <Popup trigger={<Checkbox label={storeName} disabled/>} position={'bottom center'} content={'Для выбора склада, нужно активровать его на главной странице'} />
        }
    </List.Item>
}

export default observer(UnloadStoreRaw);
