import {observer} from 'mobx-react-lite';
import React from 'react'
import {Dropdown, Grid} from 'semantic-ui-react'
import templateListStore from "../../../Stores/TemplateListStore";
import Template from "../../../Entities/Template";
import TemplateSaveButton from "./TemplateSaveButton";

function CategorySelect(props: { template: Template }) {
    const {template} = props;
    let style: any = {};
    if (template.showCategory) {
        style.paddingBottom = '0px';
    }

    return (
        <Grid verticalAlign='middle'>
            <Grid.Row style={style}>
                <Grid.Column width={5}>
                    <Dropdown fluid placeholder='Категория' selection
                              value={template.parentCategory ?? 0}
                              onChange={(_, e) => template.changeParentCategory(e.value)}
                              options={templateListStore.parentCategories.map((category) => {
                                  return {
                                      key: category.id,
                                      value: category.id,
                                      text: category.name
                                  }
                              })}/>
                </Grid.Column>
                {template.showCategory &&
                    <Grid.Column width={8} verticalAlign='middle'>
                        <Dropdown fluid placeholder='Выберите подкатегорию'
                                  selection
                                  search
                                  value={template.category}
                                  onChange={(_, e) => template.changeCategory(e.value)}
                                  options={template.categories.length === 0 ? [{
                                      text: 'Что-то не так :(',
                                      key: 123
                                  }] : template.categories.map((category) => {
                                      return {
                                          key: category.id,
                                          value: category.id,
                                          text: category.name
                                      }
                                  })}/>
                    </Grid.Column>
                }
                <Grid.Column width={3}>
                    <TemplateSaveButton fluid template={template} />
                </Grid.Column>
            </Grid.Row>
            {template.showCategory && <Grid.Row style={{paddingTop: '0px', paddingBottom: '5px'}}>
                <Grid.Column width={5}>
                </Grid.Column>
                <Grid.Column width={9} textAlign='left'>
                    <a target='_blank' href='http://autoload.avito.ru/format/zapchasti_i_aksessuary'
                       rel="noopener noreferrer">Требования Авито к заполнению полей</a>
                </Grid.Column>
            </Grid.Row>
            }
        </Grid>
    )
}

export default observer(CategorySelect);
