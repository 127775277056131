import {observer} from "mobx-react-lite";
import StoreStockFilters from "./StoreStockFilters";
import StoreList from "./StoreList";
import React from "react";

function StoreStockSettings() {
    return <>
        <StoreStockFilters/>
        <StoreList/>
        {
            //<AvitoSettings />
        }
    </>;
}

export default observer(StoreStockSettings);