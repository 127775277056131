import { observer } from 'mobx-react-lite'
import React from 'react'
import { Dropdown, Grid, Icon} from 'semantic-ui-react'
import TemplateMappingList from './TemplateMappingList'
import productFolderListStore from "../../Stores/ProductFolderListStore";
import {InformationPanelItem} from "../Dashboard/InformationPanelItem";
import templateByFoldersStore from "../../Stores/TemplateByFoldersStore";
import templateListStore from "../../Stores/TemplateListStore";

function TemplateMappingBlock() {
    return (
        <>
            <InformationPanelItem header='Связь групп товаров с шаблонами'>
                <Grid verticalAlign='middle'>
                    <Grid.Column width={9}>
                        <Dropdown fluid
                            selection
                            options={productFolderListStore.folders.filter(x => !x.templateName).map((template) => { return { text: template.name, value: template.id } })}
                            placeholder='Выберите группу'
                            search
                            value={templateByFoldersStore.folders}
                            onChange={(_, data) => templateByFoldersStore.folders = data.value ? (data.value as string[]) : []}
                            error={templateByFoldersStore.trySave && templateByFoldersStore.folders.length === 0}
                            multiple
                            noResultsMessage={'Нет групп'}
                            clearable
                            onSearchChange={(event, data) => templateByFoldersStore.searchText = data.searchQuery}
                            searchQuery={templateByFoldersStore.searchText}
                        />
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Dropdown fluid
                            selection
                            options={templateListStore.data.map((template) => { return { text: template.name, value: template.id } })}
                            placeholder='Выберите шаблон'
                            search
                            error={templateByFoldersStore.trySave && templateByFoldersStore.templateId === ''}
                            value={templateByFoldersStore.templateId}
                            onChange={(_, data) => templateByFoldersStore.templateId = data.value ? (data.value as string) : ''}
                            clearable
                        />
                    </Grid.Column>
                    <Grid.Column verticalAlign={'middle'} width={1}>
                        <Icon fitted name='save' color={templateByFoldersStore.canSave ? 'teal' : 'grey'} style={{ 'cursor': 'pointer', fontSize: '1.5em' }} onClick={templateByFoldersStore.save} />
                    </Grid.Column>
                </Grid>
                <TemplateMappingList />
            </InformationPanelItem>
        </>
    )
}

export default observer(TemplateMappingBlock);
