import { observable } from "mobx";
import {ProductFolderDto} from "../Entities/Dtos";
import api from "../Services/Api";
import {ProductFolder} from "../Entities/ProductFolder";

interface IFolderListStore {
    folders: ProductFolder[];
    load: () => void;
}

const productFolderListStore = observable<IFolderListStore>({
    folders: [],
    async load() {
        const result = await api.get('productFolder/list');
        if (result.success) {
            productFolderListStore.folders = (result.data.productFolders as ProductFolderDto[]).map((x) => new ProductFolder(x.id, x.name, x.templateName));
        }
    }
});

export default productFolderListStore;